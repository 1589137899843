import React, { useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { DateTime } from 'luxon';
import PortableText from '../components/portable-text';

export default function Opportunity({ className, title, slug, type, excerpt, endDate, setEmbed }) {
  const [color, setColor] = useState('var(--green)');
  const [text, setText] = useState(null);

  const date = DateTime.fromISO(endDate);
  const now = DateTime.now('UTC');

  const dateDiff = date.diff(now, ['days']).values?.days;

  useLayoutEffect(() => {
    if (dateDiff < 0) {
      setText(`We’re not currently accepting applications for the ${title}.`);
      setColor('var(--black)');
    } else if (dateDiff < 7) {
      setText(`Applications are closing soon for the ${title}.`);
      setColor('var(--red)');
    } else {
      setText(`Applications are now open for the ${title}.`);
    }
  }, []);

  return (
    <Link
      to={`/opportunity/${slug}`}
      className={className}
      css={css`
        text-decoration: none;

        &:hover {
          > div:first-of-type {
            color: var(--white);
            background-color: ${color};
          }
        }
      `}
    >
      <div
        css={css`
          position: relative;
          color: ${color};
          padding-bottom: 100%;
          border: 1px solid ${color};
          border-radius: ${(type === 'art') ? '100%' : 0};
          margin-bottom: var(--smallGutter);
        `}
      >
        <div
          className="type--medium"
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            text-align: center;
            padding: var(--smallGutter);
          `}
        >
          {text}
        </div>
      </div>
      {excerpt && <PortableText
        className="type--small"
        blocks={excerpt}
        setEmbed={setEmbed}
      />}
    </Link>
  );
}
